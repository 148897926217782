import Layout from '../components/Layout';
import React from 'react';

const AboutPage = () => (
  <Layout>
    <div>
      <h2>Om Oldtimer Service</h2>
      <p>Oldtimer Service ble grunnlagt i 1994.</p>
      <p>
        Vi tilbyr komplett service for 300SL, 230-280SL Pagode, W111-112 Coupé
        og cabriolet, 300 SEL 6.3, samt 600.
      </p>
      <div>
        I tillegg tilbyr vi:
        <ul css={{ marginLeft: 20 }}>
          <li>Restaureringer</li>
          <li>Komplett overhaling av motor, gir og kardang</li>
          <li>Nye ledningsett</li>
          <li>Førsteklasses salmakerarbeide</li>
          <li>Endring av utveksling på bakaksler</li>
          <li>Generell service og vedlikehold</li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
